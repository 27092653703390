<template>
  <div class="body">
    <div class="top">
      <div class="top1">
        Trang chủ  /  Bảng Xếp Hạng
        <br>
        <br>

        <h5>
          BẢNG XẾP HẠNG BÓNG ĐÁ MỚI NHẤT - BXH CỦA MỌI GIẢI ĐẤU

        </h5>
      </div>
      <div class="container">
        <div class="containerTop">
          <div style="font-size: 16px" v-for="(item,index) in countries" :key="index">
            <div
                :class="{ 'item': true, 'green': selectedIndex === index }"
                  @click="fetchFootballTotal(index,item.country_id)"
                  class="item" v-if="countryId.includes(item.country_id)">{{ item.country_name2 }}</div>
          </div>
<!--          <div style="font-size: 16px"-->
<!--               :class="{ 'item': true, 'green': selectedIndex === 100 }"-->
<!--               @click="fetchFootballLeague('3',100)"-->
<!--               class="item">C1-->
<!--          </div>-->
<!--          <div style="font-size: 16px"-->
<!--               :class="{ 'item': true, 'green': selectedIndex === 101 }"-->
<!--               @click="fetchFootballLeague('4',101)"-->
<!--               class="item">C2-->
<!--          </div>-->
<!--          <div style="font-size: 16px"-->
<!--               :class="{ 'item': true, 'green': selectedIndex === 102 }"-->
<!--               @click="fetchFootballLeague('683',102)"-->
<!--               class="item">C3-->
<!--          </div>-->
<!--          <div style="font-size: 16px"-->
<!--               :class="{ 'item': true, 'green': selectedIndex === 103 }"-->
<!--               @click="fetchFootballLeague('339',103)"-->
<!--               class="item">V LEAGUE-->
<!--          </div>-->
<!--          <div style="font-size: 16px"-->
<!--               :class="{ 'item': true, 'green': selectedIndex === 104 }"-->
<!--               @click="fetchFootballLeague('401',104)"-->
<!--               class="item">Cup-->
<!--          </div>-->
<!--          <div style="font-size: 16px"-->
<!--               :class="{ 'item': true, 'green': selectedIndex === 105 }"-->
<!--               @click="fetchFootballLeague('470',105)"-->
<!--               class="item">Super Cup-->
<!--          </div>-->
          </div>

      </div>
      <div  @click="a(item.match)" class="matchBox" v-for="(item, index) in list" :key="index">
        <div v-if="Array.isArray(item.match)" >
          <div class="matchBox-title">
            <div class="matchBox-title-left">
              <img :src=item.league_logo > <span style="  word-wrap: break-word; /* 当单词超出容器时自动换行 */">{{item.league_name}}</span>
            </div>
            <div class="matchBox-title-center">
              <div>Ranking</div>
              <div>Trận </div>
              <div>W</div>
              <div>D</div>
              <div>L</div>
              <div>GF</div>
              <div>GA</div>
              <div>PTS</div>
            </div>
          </div>
          <div v-for="(match, index) in item.match" :key="index" class="matchBox-container" >
            <div class="matchBox-container-left">
              <img  :src=match.team_badge > <div class="isShow"></div><span>{{match.team_name}}</span>
            </div>

            <div style="font-size: 16px;" class="matchBox-container-center">
              <div>{{ match.overall_league_position }}</div>
              <div>{{ match.overall_league_payed }} </div>
              <div style="color: #01b243;">{{ match.overall_league_W }}</div>
              <div style="color: #3667c9;">{{ match.overall_league_D }}</div>
              <div style="color:  #af2929;">{{ match.overall_league_L }}</div>
              <div style="color: #01b243;"> {{ match.overall_league_GF }}</div>
              <div style="color: #af2929;">{{ match.overall_league_GA }}</div>
              <div style="color: #3667c9;">{{ match.overall_league_PTS }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      countryId:[6,44,4,5,3,95,17,92,64,32,68,14,27,78,82,39,40,23,87,106,108,59,42],
      country:44,
      countries:[],
      currentDate: new Date(),
      league_name: [],
      tomorrow: [],
      list: [],
      chunkSize: 10, // 每次加载的数量
      loadedItems: 10 ,// 已加载的数量
      selectedIndex:47,
    };
  },
  computed: {
    displayedData() { // 计算属性用于返回要显示在页面上的颜色数组
      return this.total.slice(0, this.loadedItems);
    }
  },
  created() {
    this.countryId = this.countryId.map(number => number.toString());
    this.fetchFootballTotal(this.selectedIndex,this.country)
    this.fetchFootballCountries()
  },
  methods: {
    changeColor(index) {
      this.selectedIndex = index;
    },
    fetchFootballCountries() {
      const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
      axios.get(`https://apiv3.apifootball.com/?action=get_countries&APIkey=${APIkey}`)
          .then(response => {
            // 假设你已经有了国家名的映射关系

            const countryMap = {
              "England": "ANH",
              "Spain": "TBN",
              "Germany": "ĐỨC",
              "Italy": "Ý",
              "France": "PHÁP",
              "Champions League": "C1",
              "Europa League": "C2",
              "Conference League": "C3",
              "Russia": "NGA",
              "V-League": "V LEAGUE",
              "Africa Cup of Nations": "CÚP CHÂU PHI",
              "Australia": "ÚC",
              "Portugal": "BĐN",
              "Major League Soccer": "MLS",
              "Japan": "NHẬT",
              "China": "CHINA",
              "Korea Republic": "HÀN",
              "Argentina": "ARGENTINA",
              "Brazil": "BRAZIL",
              "Mexico": "MEXICO",
              "Denmark": "ĐAN MẠCH",
              "Norway": "NA UY",
              "Belgium": "BỈ",
              "Switzerland": "THỤY SĨ",
              "Egypt": "AI CẬP",
              "FA Cup": "FA CUP",
              "Thailand": "THÁI",
              "Indonesia": "INDO",
              "Bundesliga": "BUNDESLIGA 2",

              // ... 其他国家的映射关系
            };

// 假设接口返回的数组为countries
            const mappedCountries = response.data.map(country => {
              return {
                country_id: country.country_id,
                country_name: country.country_name,
                country_name2: countryMap[country.country_name] || "", // 使用映射关系添加新字段
                // 其他字段
              };
            });
            console.log('aaaaa',mappedCountries)
            this.countries = mappedCountries;

          })
          .catch(error => {
            console.error(error);
          });
    },
    a(b){
      console.log(b)
    },
    loadMore() { // 加载更多按钮点击事件处理函数
      if (this.loadedItems >= this.total.length) return; // 如果已加载的数量等于或超过原始数据数组长度，则停止加载
      this.loadedItems += this.chunkSize; // 增加已加载数量
      if (this.loadedItems > this.total.length) {
        this.loadedItems = this.total.length;
      }   // 如果超过了原始数据数组长度，则将已加载数量设为数组长度，避免超出范围

    },
fetchFootballLeague(league_id,index) {

  this.selectedIndex = index;
  this.list=[{match:{}}]
  const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
  axios.get(`https://apiv3.apifootball.com/?action=get_standings&league_id=${league_id}&APIkey=${APIkey}`)
      .then(response => {
        console.log(response)
        this.list[0].league_name=response.data[0].league_name
        this.list[0].league_logo=response.data[0].league_logo
        this.list[0].match=response.data
        console.log(this.list,'12345678910')
      })
      .catch(error => {
        console.error(error);
      });
},

    async fetchFootballTotal(index, countryId) {
      try {
        this.country = countryId;
        this.selectedIndex = index;
        this.list = [];
        const country = this.country;
        const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
        const response = await axios.get(`https://apiv3.apifootball.com/?action=get_leagues&country_id=${country}&APIkey=${APIkey}`);
        const list = response.data;
        const successfulData = [];
        await Promise.all(list.map(async item => {
          try {
            const league_id = item.league_id;
            const detailsResponse = await axios.get(`https://apiv3.apifootball.com/?action=get_standings&league_id=${league_id}&APIkey=${APIkey}`);
            item.match = detailsResponse.data;
            successfulData.push(item);
          } catch (error) {
            console.error('Failed to fetch details for league ID:', item.league_id, error);
          }
        }));
        this.list = successfulData;
        console.log('All successful data with details:', this.list);
        // 在这里进行处理和渲染详细数据
      } catch (error) {
        console.error(error);
      }
    }
  }
};

</script>
<style scoped lang="scss">
.body {
  //height: 30000px;
  background-color: #232324;
  margin-top: -1%;
}
.green {
  background-color: green;
}
@media (min-width: 700px) {
  .isShow{
    display: none;
  }
  img{
    width: 30px;
  }
}
@media (max-width: 700px) {
  img{
    width: 20px;
  }
  .top{
    width: 99%!important;
    .matchBox-title-left{
      display: flex;
      flex-direction: column;

    }
  }
}
.top {
  .matchBox {
    @media (max-width: 700px) {
      .matchBox-title{
        margin-left: -1%;
        margin-top: 2%;
        align-items: center;
        width: 100%;
        background-color: #292b2e;
        display: flex;
        height: 70px !important;
      }
    }
    .matchBox-title {
      font-size: 11px;
      margin-left: -1%;
      margin-top: 2%;
      align-items: center;
      width: 100%;
      background-color: #292b2e;
      display: flex;
      height: 50px;

      .matchBox-title-left {
        margin-left: 1%;
        width: 15%;
        justify-content: flex-start;
       @media (max-width: 700px) {
         img{
           display: none;
         }
       }
      }

      .matchBox-title-center {
        margin-left: 1%;
        width: 85%;
        display: flex;
        justify-content: center;

        div {
          display: flex;
          justify-content: center;
          width: 25%;
        }
      }

      .matchBox-title-right {
        margin-left: 1%;
        display: flex;
        justify-content: center;
        width:0%;
      }
    }

    .matchBox-container {
      margin-left: -1%;
      align-items: center;
      width: 100%;
      display: flex;
      justify-content: center;

      height: 65px;
@media (max-width: 700px) {
  .matchBox-container-left {

    font-size: 11px;
    line-height: 1.5;
    justify-content: flex-start;
flex-direction: row !important;
    margin-left: 1%;
    width: 15%;
  }
}
      .matchBox-container-left {
        justify-content: flex-start;

        margin-left: 1%;
        width: 15%;
      }

      .matchBox-container-center {
        margin-left: 1%;
        width: 85%;
        display: flex;
        justify-content: center;

        div {
          display: flex;
          justify-content: center;
          width: 33%;
        }
      }

      .matchBox-container-right {
        margin-left: 1%;
        display: flex;
        justify-content: center;
        width: 0%;
      }
    }
  }
  width: 85%;
  margin: auto;
  left: 50%;

  .container {
    margin-top: 2%;
    background: #1c1e1f;
    margin-left: -1%;
    max-width: 100%;
    width: 100%;
    //border: blue solid 1px;

    .containerTop {
      height: 100%;
      margin-left: 0;
      flex-wrap: wrap;
      display: inline-flex; /* 使用inline-flex布局 */
      .item {
        font-size: 16px; /* 设置更大的字体大小 */
        cursor: pointer;
        margin: 7px; /* 设置一些外边距 */
        //border: 1px solid #000; /* 可以添加边框以便查看每个元素的边界 */
        text-align: center; /* 居中文本 */

      }
    }
  }
}

.top1 {
  color: white;
  padding-top: 3%;

}


#wap_bottombanner {
  height: auto !important;
  bottom: 44px;
}

#wap_bottombanner a {
  display: block;
}

#wap_bottombanner br {
  display: none;
}

#wap_bottombanner_content .mb-1 {
  margin-bottom: 0px !important;
}

.menu_bottom {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  background: #292B2E;
  z-index: 999;

}

.menu_bottom ul {
  height: 2.75rem;
  display: flex;
  align-content: center;
}

.menu_bottom ul li {
  flex: 1;
  display: flex;
  align-content: center;
  justify-content: center;
}

.menu_bottom ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  color: #FFF;
  text-align: center;
  font-family: 'Raleway';
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1; /* 140% */
  gap: 0.12rem;
  text-transform: uppercase;
}

.menu_bottom ul li:hover, .menu_bottom ul li:focus {
  background: linear-gradient(102deg, #008360 4.83%, #1BB916 118.99%);
}

.menu_bottom ul li a svg {
  display: block;
  margin-bottom: 5px;
}

.menu_bottom ul li a svg path {
  fill: #fff;
}

.footer-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 728px;
  margin: 0px auto;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

#menu-footer a {
  color: #fff;
}

.footer-banner p {
  margin-bottom: 5px !important;
}

.close-ads {
  font-size: 12px;
  position: absolute;
  bottom: 100%;
  right: -2px;
  border-radius: 0px;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid #FFF;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textwidget p {
  margin-bottom: 5px;
}

.menu-cuoc-one88 a, .menu-cuoc-fabet a {
  background: #ffc107;
  color: #000 !important;
}

.menu-top-nha-cai a {
  border-radius: 3px;
  background: linear-gradient(142deg, #BB5014 0%, #8B0000 100%);
}

@media screen and (min-width: 992px) {
  #header .block-int img, .main .block-int img {
    height: 60px;
    width: 100%;
  }
}
</style>