<template>
  <div class="body">
    <div class="top">
      <div class="top1">
        Trang chủ / Tỷ Lệ Kèo
        <br>
        <br>

        <h5>
          TỶ LỆ KÈO NHÀ CÁI TRƯỚC TRẬN ĐẤU HÔM NAY - TỈ LỆ KÈO BÓNG ĐÁ

        </h5>
      </div>
      <div class="container">
        <div style="height: 1%"></div>
        <div class="container-top">
          <div class="item" style="flex-basis: 14%;">Ngày</div>
          <div class="item" style="flex-basis: 24%;">Đội bóng</div>
          <div class="item" style="flex-basis: 14%;">Tỷ số</div>
          <div class="item" style="flex-basis: 14%;">1x2</div>
          <div class="item" style="flex-basis: 17%;">HDP</div>
          <div class="item" style="flex-basis: 17%;">Tài xỉu</div>
<!--          <div class="item" style="flex-basis: 13%;">HT HDP</div>-->
<!--          <div class="item" style="flex-basis: 13%;">HT Tài xỉu</div>-->
        </div>
        <div v-for="(item, index) in total" :key="index">
          <div class="container-center">
            <div class="item" style="flex-basis: 10%;" @click=a(item)>{{ item.league_name }}</div>

          </div>
          <div class="container-foot">
            <div class="item"
                 style="color: #232425; flex-basis: 14%;display: flex;flex-direction: column;justify-content: center;align-items: center;font-size: 12px">
              <div>{{ item.match_date}}<br>{{ item.match_time}}</div>
            </div>
            <div class="item"
                 style="color: #232425; flex-basis: 24%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block3" >
                {{ item.match_hometeam_name }}
              </div>
              <div style="color: red">VS</div>
              <div class="block3" >
                {{ item.match_awayteam_name }}
              </div>
              <div class="block3">
                H
              </div>
            </div>
            <div class="item"
                 style="color: #0089F1;flex-basis: 14%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div>{{ item.match_hometeam_score }} : {{item.match_awayteam_score}}</div>
            </div>
            <div class="item"
                 style="color: #FF4C13;flex-basis: 14%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block3">
                {{ item.odd_1 }}
              </div>
              <div class="block3">
                {{ item.odd_2 }}
              </div>
              <div class="block3">
                {{ item.odd_x }}

              </div>
            </div>

            <div v-if="item['ah+1.5_1']" class="item"
                 style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block2">
                1.5 {{item['ah+1.5_1']}}
              </div>
              <div class="block2">
                {{ item['ah+1.5_2'] }}
              </div>
              <div class="block2">

              </div>
            </div>
            <div v-else-if="item['ah+2.5_1']" class="item"
                 style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block2">
                2.5 {{item['ah+2.5_1']}}
              </div>
              <div class="block2">
                {{ item['ah+2.5_2'] }}
              </div>
              <div class="block2">

              </div>
            </div>
            <div v-else-if="item['ah+3.5_1']" class="item"
                 style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block2">
                3.5 {{item['ah+3.5_1']}}
              </div>
              <div class="block2">
                {{ item['ah+3.5_2'] }}
              </div>
              <div class="block2">

              </div>
            </div>
            <div v-else-if="item['ah+4.5_1']" class="item"
                 style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block2">
                4.5 {{item['ah+4.5_1']}}
              </div>
              <div class="block2">
                {{ item['ah+4.5_2'] }}
              </div>
              <div class="block2">

              </div>
            </div>
            <div v-else-if="item['ah+0.5_1']" class="item"
                 style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block2">
                0.5 {{item['ah+0.5_1']}}
              </div>
              <div class="block2">
                {{ item['ah+0.5_2'] }}
              </div>
              <div class="block2">

              </div>
            </div>
            <div v-else class="item"
                 style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block2">
                0 {{item['ah0_1']}}
              </div>
              <div class="block2">
                {{ item['ah0_2'] }}
              </div>
              <div class="block2">

              </div>
            </div>

            <div v-if="item['o+2.5']" class="item"
                 style="flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block3">
                <span>O</span>  &nbsp;<span style="color: #0A7FD0">2.5</span>&nbsp; <span>{{item['o+2.5']}}</span>
              </div>
              <div class="block3">
                <span>U</span> &nbsp; <span style="color: #0A7FD0">2.5</span>&nbsp; <span>{{item['u+2.5']}}</span>
              </div>
              <div class="block3">

              </div>
            </div>
            <div v-else-if="item['o+1.5']" class="item"
                 style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block3">
                <span>O</span> &nbsp; <span style="color: #0A7FD0">1.5</span> &nbsp;<span>{{item['o+1.5']}}</span>
              </div>
              <div class="block3">
                <span>U</span> &nbsp; <span style="color: #0A7FD0">1.5</span> &nbsp;<span>{{item['u+1.5']}}</span>
              </div>
              <div class="block3">

              </div>
            </div>
            <div v-else-if="item['o+0.5']" class="item"
                 style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block3">
               <span>O</span> &nbsp;<span style="color: #0A7FD0">0.5</span> &nbsp;<span>{{item['o+0.5']}}</span>
              </div>
              <div class="block3">
                <span>U</span> &nbsp;<span style="color: #0A7FD0">0.5</span>&nbsp;<span>{{item['u+0.5']}}</span>
              </div>
              <div class="block3">

              </div>
            </div>
            <div v-else-if="item['o+3.5']" class="item"
                 style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block3">
                <span>O</span> &nbsp; <span style="color: #0A7FD0">3.5</span> &nbsp;<span>{{item['o+3.5']}}</span>
              </div>
              <div class="block3">
                <span>U</span>  &nbsp;<span style="color: #0A7FD0">3.5</span> &nbsp;<span>{{item['u+3.5']}}</span>
              </div>
              <div class="block3">

              </div>
            </div>
            <div v-else-if="item['o+4.5']" class="item"
                 style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block3">
                <span>O</span> &nbsp; <span style="color: #0A7FD0">4.5</span> &nbsp;<span>{{item['o+4.5']}}</span>
              </div>
              <div class="block3">
                <span>U</span>  &nbsp;<span style="color: #0A7FD0">4.5</span> &nbsp;<span>{{item['u+4.5']}}</span>
              </div>
              <div class="block3">

              </div>
            </div>
            <div v-else-if="item['o+5.5']" class="item"
                 style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block3" style="padding-left: 20%">
                <span>O</span>  &nbsp;<span style="color: #0A7FD0">5.5</span> &nbsp;<span>{{item['o+5.5']}}</span>
              </div>
              <div class="block3">
               <span>U</span>   &nbsp;<span style="color: #0A7FD0">5.5</span>&nbsp;<span>{{item['u+5.5']}}</span>
              </div>
              <div class="block3">

              </div>
            </div>
            <div v-else class="item"
                 style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div class="block3">
<!--                O {{item.o+1.5}}-->
              </div>
              <div class="block3">
<!--                U {{item.u+1.5}}-->
              </div>
              <div class="block3">

              </div>
            </div>

<!--            <div class="item"-->
<!--                 style="color: #FF4C13;flex-basis: 13%;display: flex;flex-direction: column;justify-content: center;align-items: center;">-->
<!--              <div class="block2">-->

<!--              </div>-->
<!--              <div class="block2">-->

<!--              </div>-->
<!--              <div class="block2">-->

<!--              </div>-->
<!--            </div>-->
<!--            <div class="item"-->
<!--                 style="color: #FF4C13;flex-basis: 13%;display: flex;flex-direction: column;justify-content: center;align-items: center;">-->
<!--              <div class="block3">-->

<!--              </div>-->
<!--              <div class="block3">-->

<!--              </div>-->
<!--              <div class="block3">-->

<!--              </div>-->
<!--            </div>-->

          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      league_name: [],
      yesterday: [],
      tomorrow: [],
      total: [],
      chunkSize: 10, // 每次加载的数量
      loadedItems: 10 // 已加载的数量
    };
  },
  computed: {
    displayedData() { // 计算属性用于返回要显示在页面上的颜色数组
      return this.total.slice(0, this.loadedItems);
    }
  },
  mounted() {
    this.fetchFootballTotal();//返回赔率数据
  },
  methods: {
    a(item) {
      console.log(item['ah+2.5_1'])
    },
    goToDetailPage() {
      this.$router.push({name: 'oddsPage'});
    },
    loadMore() { // 加载更多按钮点击事件处理函数
      if (this.loadedItems >= this.total.length) return; // 如果已加载的数量等于或超过原始数据数组长度，则停止加载
      this.loadedItems += this.chunkSize; // 增加已加载数量
      if (this.loadedItems > this.total.length) {
        this.loadedItems = this.total.length;
      }   // 如果超过了原始数据数组长度，则将已加载数量设为数组长度，避免超出范围

    },
    fetchFootballDetail() {
      const id = this.$route.query.id;
      const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';

      axios.get(`https://apiv3.apifootball.com/?action=get_events&match_id=${id}&APIkey=${APIkey}`)
          .then(response => {
            this.list = response.data[0];
            // console.log(this.list)

          })
          .catch(error => {
            console.error(error);
          });
    },
    async fetchFootballTotal() {
      try {
        const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
        const today = new Date().toISOString().slice(0, 10);
        const response = await axios.get(`https://apiv3.apifootball.com/?action=get_odds&from=${today}&to=${today}&odd_bookmakers=Marathon&APIkey=${APIkey}`);
        const total = response.data;
        const successfulData = [];
        await Promise.all(total.map(async item => {
          try {
            const id = item.match_id;
            const detailsResponse = await axios.get(`https://apiv3.apifootball.com/?action=get_events&match_id=${id}&APIkey=${APIkey}`);
            const details = detailsResponse.data[0];
            item.match_date = details.match_date;
            item.match_time = details.match_time;
            item.league_name = details.league_name;
            item.match_hometeam_score = details.match_hometeam_score
            item.match_awayteam_score = details.match_awayteam_score
            item.match_hometeam_name = details.match_hometeam_name;
            item.match_awayteam_name = details.match_awayteam_name;
            successfulData.push(item);
          } catch (error) {
            console.error('Failed to fetch details for match ID:', item.match_id, error);
          }
        }));
        this.total = successfulData;
        console.log('All successful data with details:', this.total);
        // 在这里进行处理和渲染详细数据
      } catch (error) {
        console.error(error);
      }
    }
  }
};

</script>
<style scoped lang="scss">
@media (min-width: 700px) {
  .body {
    //height: 30000px;
    background-color: #232324;
    margin-top: -1%;
  }

  .top {

    //height: 50px;
    width: 80%;
    margin: auto;
    left: 50%;

  }

  .top1 {
    color: white;
    padding-top: 3%;

  }

  .container {
    background-color: white;
    width: 100%;


    .container-foot {
      display: flex;

      height: 120px;
      background: white;
      width: 102%;
      margin-left: -1%;
      //border: black solid 1px;

      .item {
        //border: black solid 1px;
        .block1 {
          background-color: lightblue;
          width: 100%;
          height: 100%;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          //border: 1px solid black;
        }

        .block3 {
          span{
            width: 15%;
          }
          width: 100%;
          height: 33%;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          //border: 1px solid black;
        }

        .block2 {

          width: 100%;
          height: 33%;
          margin: auto;
          padding-right: 10%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          //border: 1px solid black;
        }
      }
    }

    .container-center {
      height: 40px;
      background: #f0f0f0;
      width: 102%;
      margin-left: -1%;

      .item {
        display: flex;
        padding-left: 2%;
        align-items: center;
        padding-top: 0.8%
      }
    }

    .container-top {
      width: 102%;
      margin-left: -1%;
      display: flex;
      height: 40px;
      background-color: green;

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid white;
      }
    }
  }

  #wap_bottombanner {
    height: auto !important;
    bottom: 44px;
  }

  #wap_bottombanner a {
    display: block;
  }

  #wap_bottombanner br {
    display: none;
  }

  #wap_bottombanner_content .mb-1 {
    margin-bottom: 0px !important;
  }

  .menu_bottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background: #292B2E;
    z-index: 999;

  }

  .menu_bottom ul {
    height: 2.75rem;
    display: flex;
    align-content: center;
  }

  .menu_bottom ul li {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .menu_bottom ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    color: #FFF;
    text-align: center;
    font-family: 'Raleway';
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1; /* 140% */
    gap: 0.12rem;
    text-transform: uppercase;
  }

  .menu_bottom ul li:hover, .menu_bottom ul li:focus {
    background: linear-gradient(102deg, #008360 4.83%, #1BB916 118.99%);
  }

  .menu_bottom ul li a svg {
    display: block;
    margin-bottom: 5px;
  }

  .menu_bottom ul li a svg path {
    fill: #fff;
  }

  .footer-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 728px;
    margin: 0px auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  #menu-footer a {
    color: #fff;
  }

  .footer-banner p {
    margin-bottom: 5px !important;
  }

  .close-ads {
    font-size: 12px;
    position: absolute;
    bottom: 100%;
    right: -2px;
    border-radius: 0px;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #FFF;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textwidget p {
    margin-bottom: 5px;
  }

  .menu-cuoc-one88 a, .menu-cuoc-fabet a {
    background: #ffc107;
    color: #000 !important;
  }

  .menu-top-nha-cai a {
    border-radius: 3px;
    background: linear-gradient(142deg, #BB5014 0%, #8B0000 100%);
  }

  @media screen and (min-width: 992px) {
    #header .block-int img, .main .block-int img {
      height: 60px;
      width: 100%;
    }
  }
}
@media (max-width: 700px) {
  .body {
    //height: 30000px;
    background-color: #232324;
    margin-top: -1%;
  }

  .top {

    //height: 50px;
    width: 95%;
    margin: auto;
    left: 50%;

  }

  .top1 {
    color: white;
    padding-top: 3%;

  }

  .container {
    background-color: white;
    width: 100%;


    .container-foot {
      display: flex;

      height: 120px;
      background: white;
      width: 102%;
      margin-left: -1%;
      //border: black solid 1px;

      .item {
        //border: black solid 1px;
        .block1 {
          background-color: lightblue;
          width: 100%;
          height: 100%;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          //border: 1px solid black;
        }

        .block3 {
          span{
            width: 25%;
          }
          font-size: 12px;
          width: 100%;
          height: 33%;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          //border: 1px solid black;
        }

        .block2 {
          font-size: 13px;

          width: 100%;
          height: 33%;
          margin: auto;
          padding-right: 10%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          //border: 1px solid black;
        }
      }
    }

    .container-center {
      height: 40px;
      background: #f0f0f0;
      width: 102%;
      margin-left: -1%;

      .item {
        display: flex;
        padding-left: 2%;
        align-items: center;
        padding-top: 0.8%
      }
    }

    .container-top {
      width: 102%;
      margin-left: -1%;
      display: flex;
      height: 40px;
      background-color: green;

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid white;
      }
    }
  }

  #wap_bottombanner {
    height: auto !important;
    bottom: 44px;
  }

  #wap_bottombanner a {
    display: block;
  }

  #wap_bottombanner br {
    display: none;
  }

  #wap_bottombanner_content .mb-1 {
    margin-bottom: 0px !important;
  }

  .menu_bottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background: #292B2E;
    z-index: 999;

  }

  .menu_bottom ul {
    height: 2.75rem;
    display: flex;
    align-content: center;
  }

  .menu_bottom ul li {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .menu_bottom ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    color: #FFF;
    text-align: center;
    font-family: 'Raleway';
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1; /* 140% */
    gap: 0.12rem;
    text-transform: uppercase;
  }

  .menu_bottom ul li:hover, .menu_bottom ul li:focus {
    background: linear-gradient(102deg, #008360 4.83%, #1BB916 118.99%);
  }

  .menu_bottom ul li a svg {
    display: block;
    margin-bottom: 5px;
  }

  .menu_bottom ul li a svg path {
    fill: #fff;
  }

  .footer-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 728px;
    margin: 0px auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  #menu-footer a {
    color: #fff;
  }

  .footer-banner p {
    margin-bottom: 5px !important;
  }

  .close-ads {
    font-size: 12px;
    position: absolute;
    bottom: 100%;
    right: -2px;
    border-radius: 0px;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #FFF;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textwidget p {
    margin-bottom: 5px;
  }

  .menu-cuoc-one88 a, .menu-cuoc-fabet a {
    background: #ffc107;
    color: #000 !important;
  }

  .menu-top-nha-cai a {
    border-radius: 3px;
    background: linear-gradient(142deg, #BB5014 0%, #8B0000 100%);
  }

  @media screen and (min-width: 992px) {
    #header .block-int img, .main .block-int img {
      height: 60px;
      width: 100%;
    }
  }
}
</style>